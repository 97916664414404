/* Main container */
.App {
  text-align: center;
  margin: 0 0.15rem;
  font-family: "Work Sans", sans-serif;
  background-color: green;
  font-size: 1rem;
}

/* Container header */
.Container-Header {
  background-color: rgba(223, 65, 58, 255);
  display: grid;
  grid-template-rows: auto;
  justify-content: normal;
  color: white;
  align-items: baseline;
}

.container-logo {
  display: grid;
  justify-items: normal;
  align-items: center;
  font-size: 2rem;
  max-height: fit-content;
}
.title-logo {
  background-color: aliceblue;
  color: rgba(223, 65, 58, 255);
}
span {
  color: green;
}

.container-timer {
  background-color: green;
  display: grid;
}
.title-timer {
  font-size: 2rem;
}

.data-timer {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  font-size: 1.25rem;
  margin-bottom: 1rem;
}

.Container-Feliz {
  display: grid;
  grid-template-columns: repeat(2, auto);
  grid-template-rows: repeat(5, auto);
  gap: 0.25rem;
}

/* Container Form */
.Form {
  display: grid;
  grid-template-columns: 1fr;
  justify-items: center;
}

.form-elements {
  display: grid;
  grid-template-rows: auto;
  gap: 0.5rem;
  /* justify-items: center; */
}
.titleForm {
  font-size: 1.5rem;
}

input[type="text"],
input[type="email"],
input[type="number"],
input[type="behave"],
input[type="comments"],
textarea {
  border: crimson thin dotted;
  border-radius: 0.5rem;
  padding: 0.75rem;
  margin: 0.25rem 0 1rem;
  width: 80%;
  font-size: 1rem;
  line-height: 1;
  background-color: whitesmoke;
}

textarea {
  font-family: "Work Sans", sans-serif;
  resize: none;
  color: green;
  font-size: 2rem;
}

.question {
  color: black;
  font-size: 1.5rem;
  font-weight: 400;
}
.error {
  color: yellow;
  font-size: 1.5rem;
}

.button {
  border: none;
  height: 3rem;
  width: 80%;
  background-color: rgba(223, 65, 58, 255);
  font-size: 1rem;
  margin-bottom: 2rem;
  border-radius: 10rem;
  color: white;
  cursor: pointer;
}
.button:hover {
  background-color: white;
  color: rgba(223, 65, 58, 255);
  font-size: 1.5rem;
  height: 3.5rem;
  width: 90%;
}

/* Footer */

.footter {
  height: 10rem;
  background-color: green;
  background-size: 15vw;
  background-image: url("santa.svg");
  background-position: left;
  background-repeat: space;
}

a:link {
  text-decoration: white;
  color: aliceblue;
}

a:visited {
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

a:active {
  text-decoration: none;
}

/* media */

/* REVISAR TODO DESDE AQUI, PORQUE HE CAMBIADO CLASES */

@media only screen and (min-width: 45rem) {
  .App {
    background-size: 80vw;
    background-image: url("photo.svg");
    background-repeat: no-repeat;
    background-position: left;
    background-position-y: 45rem;
    background-position-x: -3rem;
    font-size: 1.5rem;
  }

  .container-logo {
    font-size: 3rem;
  }

  .title-timer {
    font-size: 3rem;
  }

  .data-timer {
    font-size: 1.5rem;
  }

  .Container-Feliz {
    grid-template-columns: repeat(5, auto);
    grid-template-rows: repeat(2, auto);
  }

  .Form {
    grid-template-columns: 30% 1fr;
    background-image: url("gift.svg");
    background-repeat: no-repeat;
    background-position: left;
    background-position-y: 80rem;
    background-position-x: -1rem;
    background-size: 30vw;
  }

  .FormContainer {
    grid-column: 2/3;
  }

  input[type="text"],
  input[type="email"],
  input[type="number"],
  textarea {
    font-size: 1.5rem;
    margin: 0.25rem 0 2rem;
  }

  .titleForm {
    font-size: 2.5rem;
  }
  .question {
    font-size: 2rem;
  }

  .button {
    height: 4rem;
    font-size: 2rem;
  }
  .button:hover {
    font-size: 3rem;
    height: 4.5rem;
  }
}

@media only screen and (min-width: 60rem) {
  .App {
    background-position-y: 40rem;
    background-position-x: -10rem;
  }

  .Container-Header {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: repeat(2, 1fr);
    gap: 1rem;
    background-size: 15vw;
    background-image: url("duende.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-position-y: 10rem;
    background-position-x: 10rem;
  }

  input[type="text"],
  input[type="email"],
  input[type="number"],
  textarea {
    margin: 0.25rem 0 2.75rem;
  }

  .container-timer {
    grid-column: 2/3;
    border-radius: 2rem;
    margin: 0 0.5rem;
  }
  .Container-Feliz {
    grid-column: 1/3;
    font-size: 2rem;
  }

  .button {
    height: 5rem;
    font-size: 3rem;
  }
  .button:hover {
    font-size: 4rem;
    height: 5.5rem;
  }
  .footter {
    height: 22rem;
    background-size: 18vw;
  }
}
@media only screen and (min-width: 80rem) {
  .FormContainer {
    width: 100%;
  }
  .footter {
    height: 25rem;
    background-size: 20vw;
  }
}
